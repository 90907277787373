

.calenderHeader{
    height:50px; 
    width: 70px;
    padding: 'auto';
    text-align:'center'; 
    font-weight:'bolder';
    margin: 2px;
    align-items: 'center';
    justify-items: 'center';

}
.tablerow2{
    height:80px; 
    width: 70px;
    padding: '0px';
}
.tablerow1{
    height:80px; 
    width: 70px;
    padding: '0px';
   
    color:white;
}
.mytables th {
    height:30px;
    padding: 2px;
    font-size: 1.0em !important;
    font-family: 'Josefin Sans', sans-serif;
    color: white;
    background-color: gray;
}
.mytables td{
    height:30px;
    padding: 3px;
    margin: 1px;
    font-size: 0.9em;
}
.roomshow:hover{
    border-color: orangered;
    border-width: 4px;
    font-size: larger;
    padding:10px;
    overflow: hidden;

}

.roomshow:hover:before{
    left: 7px
}

.floatblock{
    position: absolute;
    top:0px;
    z-index:100;
    border: none;
    background-image:linear-gradient(to bottom right,#434343, #000000);
    margin: 0px;
    left: 0px;
}
.floatlink{
    list-style: none; 
    margin: 1px;
    padding: 2px; 
}
.floatlink a{
    margin: 1px;
    padding: 2px;
    color:white;
    font-size: 0.8rem;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 100%;


}
.floatlink li{
    margin: 0px;
    padding: 2px;
    border-bottom: 1px solid grey;

}

.reportcontrol{
    display: inline-flex;
    font-size: larger;
    margin-left: 2px;
}
.reportcontrol a{
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.sidebarmenu, .sidebarmenus {
    color: silver;
    size: 1.6em;
    list-style: none;
    display: flex;
    flex-direction: row;
    -ms-flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 15px;
    padding: 2px;
    cursor: pointer;
}
.sidebarmenu:hover {
    color:#fff;
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);

}
.mainname {
    color:'#f68';
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.6), 0px 8px 13px rgba(0, 0, 0, 0.4), 0px 18px 23px rgba(0, 0, 0, 0.1);

}
.shadowlogo {
    text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.9), 0px 8px 13px rgba(0, 0, 0, 0.4), 0px 18px 23px rgba(0, 0, 0, 0.1);

}
.changeloc{
    margin: 5px;
    padding:2px;
    opacity: 0.5;
    font-family: "Poiret One";
}
.changeloc1{
    margin: 5px;
    padding:2px;
    margin-top: 0.4em;
    opacity: 1 !important;
    font: '1.3em' !important;
    font-family: 'Josefin Sans', sans-serif !important;
    cursor: zoom-out !important;
}
.changeloc:hover {
    opacity: 1;
    font: '1.3em';
    font-family: 'Josefin Sans', sans-serif;
    cursor: pointer;
}
.parent { width: 250px; height:125px;}
.box {
    width: 30px;
    height: 100px;
    background-color: red;
    font-size: 20px;
    left: 0px;
    top: 0px;
    position: absolute;
    -webkit-transition-property: width height background-color font-size left top transform -webkit-transform color;
    -webkit-transition-duration: 2s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height background-color font-size left top transform -webkit-transform color;
    transition-duration: 2s;
    transition-timing-function: ease-in-out;
}
.box1{
    transform: rotate(270deg);
    -webkit-transform: rotate(270deg);
    width: 50px;
    height: 50px;
    background-color: blue;
    color: yellow;
    font-size: 18px;
    left: 150px;
    top: 25px;
    position: absolute;
    -webkit-transition-property: width height background-color font-size left top transform -webkit-transform color;
    -webkit-transition-duration: 2s;
    -webkit-transition-timing-function: ease-in-out;
    transition-property: width height background-color font-size left top transform -webkit-transform color;
    transition-duration: 2s;
    transition-timing-function: ease-in-out;
}
.sidebarmenu span {
    margin:0px;
    padding:4px;
    margin-left:15px;
    margin-left: 15px;
    min-width: 30px;
}
.sidebarmenu span .badge {
    margin:0px;
    padding:4px;
    min-width: 30px;
}
.sidebarmenu p {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: none;
    margin:0px;
    margin-left: 50px;
    width:60%;
}
.sidebarmenu p :hover {
    text-shadow: 0px 18px 23px rgba(0, 0, 0, 0.1);
}
 .sidebarmenux{
    font-family: 'Josefin Sans', sans-serif;
    text-transform: none;
    margin:0px;
    margin-left: 35px !important;
    padding-left: 9px !important;
    width:100%;
    cursor: pointer;
    font-size: '1.9em'
 }
 .sidebarmenux span .badge {
    margin:0px;
    padding:4px;
}
.sidebarmenus p {
    font-family: 'Josefin Sans', sans-serif;
    text-transform: none;
    margin:0px;
    margin-left: 50px;
    padding-left: 50px;
    width:60%;
    cursor: pointer;
}

.tagheader{
    color: steelblue;
    margin: 9px !important;
    padding-left: 15px !important;
    text-align: left !important;
}

.tagdata{
    color: #000;
    margin: 9px !important;
    padding-left: 15px !important;
    text-align: left !important;
    font-size: 1.0em !important;
    font-family: 'Josefin Sans', sans-serif;
}
.inventoryapp{
    color: green;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.inventoryuapp{
    color: red;
    font-weight: bolder;
    font-family: monospace;
}

@media print {
    .sidebar{
        display:none !important;
        visibility: hidden !important;
        margin-left: 0px;
        padding-left: 0px !important;
    }
}
.table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
  }